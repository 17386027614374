import useWindowSize from '@hooks/app/useWindowSize';

import StepperHorizontal from './StepperHorizontal/StepperHorizontal';
import StepperVertical from './StepperVertical/StepperVertical';

export default function Stepper({ stepNames, currStep }) {
    const { isMobile } = useWindowSize();

    return (
        <>
            {isMobile ? (
                <StepperHorizontal stepNames={stepNames} currStep={currStep} />
            ) : (
                <StepperVertical stepNames={stepNames} currStep={currStep} />
            )}
        </>
    );
}
