import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Divider } from '@mui/material';
import cx from 'classnames';

import useStepper from '@components/Stepper/hook';

import './StepperVertical.scss';
import { ReactComponent as IconTick } from '@images/iconGeneral/tick.svg';

export default function StepperVertical({ stepNames, currStep }) {
    const { t } = useTranslation();
    const { currStepIndex, camelStepNames } = useStepper(stepNames, currStep);
    const isAllCompleted = currStepIndex === camelStepNames.length - 1;

    return (
        <Box className="stepper stepperVertical">
            {camelStepNames.map((camelStepName, index) => {
                const isCompletedStep = index < currStepIndex;
                const isCurrIndex = index === currStepIndex;
                const isLastStep = index === camelStepNames.length - 1;
                return (
                    <Box
                        key={camelStepName}
                        className={cx([
                            'step',
                            { currStep: isCurrIndex },
                            { completedStep: isCompletedStep }
                        ])}>
                        <Box className={'stepItm'} key={camelStepName}>
                            {isAllCompleted ? (
                                <Box className="stepIndex">
                                    <IconTick />
                                </Box>
                            ) : (
                                <>
                                    {isCompletedStep && (
                                        <Box className="stepIndex">
                                            <IconTick />
                                        </Box>
                                    )}
                                    {isCurrIndex && (
                                        <Box className="stepIndex">
                                            {index + 1}
                                        </Box>
                                    )}
                                    {!isCompletedStep && !isCurrIndex && (
                                        <Box className="stepIndex">
                                            {index + 1}
                                        </Box>
                                    )}
                                </>
                            )}
                            <Box className="stepName">
                                {t('common.step.' + camelStepName)}
                            </Box>
                        </Box>
                        {!isLastStep && (
                            <Divider
                                className="stepVerticalLine"
                                orientation="vertical"
                            />
                        )}
                    </Box>
                );
            })}
        </Box>
    );
}
