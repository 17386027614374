export const paletteMui = {
    primary: {
        main: '#fb6100',
        contrastText: '#fff'
    },
    secondary: {
        main: '#B0B0B0',
        contrastText: '#fff'
    }
};

export const paletteCustomize = {
    ...paletteMui,
    imgOutline: paletteMui.primary.main
};
