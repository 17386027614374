import { createTheme } from '@mui/material/styles';

import { paletteMui } from './palette';

export const theme = createTheme({
    palette: paletteMui,
    spacing: 2,
    components: {
        styleOverrides: {
            MuiButton: {
                root: {
                    borderRadius: '27px'
                }
            }
        },
        MuiAlert: {
            styleOverrides: {
                root: {
                    alignItems: 'center'
                }
            }
        },
        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    padding: '8px'
                }
            }
        },
        MuiDialogContent: {
            styleOverrides: {
                dividers: {
                    padding: '8px',
                    minHeight: '120px'
                }
            }
        }
    }
});
