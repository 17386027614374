import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Divider } from '@mui/material';
import cx from 'classnames';

import useStepper from '@components/Stepper/hook';

import './StepperHorizontal.scss';
import { ReactComponent as IconTick } from '@images/iconGeneral/tick.svg';

export default function StepperHorizontal({ stepNames, currStep }) {
    const { t } = useTranslation();
    const { currStepIndex, camelStepNames } = useStepper(stepNames, currStep);
    const isAllCompleted = currStepIndex === camelStepNames.length - 1;

    return (
        <Box className="stepper stepperHorizontal">
            {camelStepNames.map((camelStepName, index) => {
                const isCompletedStep = index < currStepIndex;
                const isCurrIndex = index === currStepIndex;
                const isLastStep = index === camelStepNames.length - 1;
                return (
                    <Box
                        key={camelStepName}
                        className={cx([
                            'step',
                            { currStep: isCurrIndex },
                            { completedStep: isCompletedStep }
                        ])}>
                        <Box className="stepItm" key={camelStepName}>
                            <Box className="stepConnector">
                                {!isLastStep && (
                                    <Divider
                                        className="stepHorizontalLine"
                                        orientation="horizontal"
                                    />
                                )}

                                {isAllCompleted ? (
                                    <Box className="stepIndex Finished">
                                        <IconTick className="tickIco" />
                                    </Box>
                                ) : (
                                    <>
                                        {isCompletedStep && (
                                            <Box className="stepIndex stepFinished">
                                                <IconTick className="tickIco" />
                                            </Box>
                                        )}
                                        {isCurrIndex && (
                                            <Box className="stepIndex">
                                                {index + 1}
                                            </Box>
                                        )}
                                        {!isCompletedStep && !isCurrIndex && (
                                            <Box className="stepIndex">
                                                {index + 1}
                                            </Box>
                                        )}
                                    </>
                                )}
                            </Box>

                            <Box className="stepName">
                                {t('common.step.' + camelStepName)}
                            </Box>
                        </Box>
                    </Box>
                );
            })}
        </Box>
    );
}
